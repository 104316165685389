export function getFormattedUTCDate() {
  const currentDate = new Date();

  // Get individual components
  const day = String(currentDate.getUTCDate()).padStart(2, "0"); // Day with leading zero
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Month with leading zero
  const year = currentDate.getUTCFullYear();

  const hours = String(currentDate.getUTCHours()).padStart(2, "0");
  const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");

  // Get day name (e.g., Mon, Tue, etc.)
  const dayName = currentDate.toLocaleString("en-GB", {
    weekday: "short",
    timeZone: "UTC",
  });

  // Combine all parts into the desired format
  const formattedDate = `${day}-${month}-${year} ${dayName} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

export function getNames(name) {
  if (!name) {
    return {
      firstName: "",
      lastName: "",
    };
  }
  const names = name?.split(" ");
  if (names.length > 1) {
    return {
      firstName: names[0],
      lastName: names[1],
    };
  }
  return {
    firstName: names[0],
    lastName: "",
  };
}

export function pick(obj, keys) {
  return keys.reduce((result, key) => {
    if (key in obj) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export function omit(obj, keys) {
  return Object.keys(obj).reduce((result, key) => {
    if (!keys.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export function getFileNameFromPath(path) {
  return path.replace(/^[\w-]+-/, "");
}

export function getOriginalFilename(url) {
  const parsedUrl = new URL(url);
  const path = parsedUrl.pathname;

  const filenameWithPrefix = path.split("/").pop();
  const originalFilename = filenameWithPrefix.replace(/^[\w-]+-/, "");
  return originalFilename;
}

//function to get initials of names, example "John Doe" -> "JD"
export function getInitials(name) {
  if (!name) return "";
  const names = name.split(" ");
  return names
    .map((n) => n[0])
    .join("")
    .toUpperCase();
}

export function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleString(); // Returns the date in a human-readable format
}

export function isValidDurationAndDate(data) {
  const { date, startTime, endTime } = data;
  const start = new Date(`${date}T${startTime}:00`);
  const end = new Date(`${date}T${endTime}:00`);
  if (end <= start) {
    return {
      isValid: false,
      message: "End date and time must be greater than start date.",
    };
  }
  const differenceInMilliseconds = end - start;
  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
  if (differenceInMinutes > 10) {
    return {
      isValid: false,
      message: "Recording duration cannot exceed 10 minutes",
    };
  }
  return {
    isValid: true,
    message: "The duration and date are valid.",
  };
}

export function formatDate(isoString) {
  const date = new Date(isoString);
  const day = date.getUTCDate();
  const month = date.toLocaleString("en-US", {
    month: "short",
    timeZone: "UTC",
  });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
}

export function extractReportNumber(url) {
  const filePathMatch = url.split("?")[0];
  const reportPattern = /Report_(\d+)_(TR|BR)/;
  const match = filePathMatch.match(reportPattern);
  return match ? parseInt(match[1]) : null;
}

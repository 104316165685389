import React, { useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Scatter,
  ScatterChart,
  ZAxis,
} from "recharts";
import { styled } from "@stitches/react";
import {
  Box,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Text,
} from "@radix-ui/themes";
import FilterSelect from "./FilterSelect";
import { useAppContext } from "../context/appContext";

const floorsData = {
  floors_against_designed: [
    { floors: 0, date: "2024-09-29" },
    { floors: 0, date: "2024-10-24" },
    { floors: 0, date: "2024-10-27" },
    { floors: 1, date: "2024-11-14" },
    { floors: 1, date: "2024-11-17" },
    { floors: 1, date: "2024-12-05" },
    { floors: 1, date: "2024-12-08" },
    { floors: 2, date: "2024-12-19" },
    { floors: 2, date: "2024-12-22" },
    { floors: 2, date: "2025-01-02" },
    { floors: 2, date: "2025-01-05" },
    { floors: 3, date: "2025-01-15" },
    { floors: 3, date: "2025-01-16" },
    { floors: 3, date: "2025-01-28" },
    { floors: 3, date: "2025-01-29" },
    { floors: 4, date: "2025-02-10" },
    { floors: 4, date: "2025-02-11" },
    { floors: 4, date: "2025-02-23" },
    { floors: 4, date: "2025-02-24" },
    { floors: 5, date: "2025-03-06" },
    { floors: 5, date: "2025-03-09" },
    { floors: 5, date: "2025-03-19" },
    { floors: 5, date: "2025-03-20" },
    { floors: 6, date: "2025-04-01" },
    { floors: 6, date: "2025-04-02" },
    { floors: 6, date: "2025-04-14" },
    { floors: 6, date: "2025-04-15" },
    { floors: 7, date: "2025-04-27" },
    { floors: 7, date: "2025-04-28" },
    { floors: 7, date: "2025-05-08" },
    { floors: 7, date: "2025-05-11" },
    { floors: 8, date: "2025-05-21" },
    { floors: 8, date: "2025-05-22" },
    { floors: 8, date: "2025-06-02" },
    { floors: 8, date: "2025-06-03" },
    { floors: 9, date: null },
    { floors: 9, date: null },
    { floors: 9, date: null },
    { floors: 9, date: null },
    { floors: 10, date: null },
  ],
  floors_against_east: [
    { floors: 0, date: "2024-09-25" },
    { floors: 0, date: "2024-10-17" },
    { floors: 0, date: "2024-11-05" },
    { floors: 1, date: "2024-11-06" },
    { floors: 1, date: "2024-11-06" },
    { floors: 1, date: "2024-11-19" },
    { floors: 1, date: "2024-11-20" },
    { floors: 2, date: "2024-11-25" },
    { floors: 2, date: "2024-11-25" },
    { floors: 2, date: "2024-12-01" },
    { floors: 2, date: "2024-12-02" },
    { floors: 3, date: "2024-12-04" },
    { floors: 3, date: "2024-12-05" },
    { floors: 3, date: "2024-12-10" },
    { floors: 3, date: "2024-12-11" },
    { floors: 4, date: "2024-12-15" },
    { floors: 4, date: "2024-12-15" },
    { floors: 4, date: "2024-12-22" },
    { floors: 4, date: "2024-12-23" },
    { floors: 5, date: "2024-12-26" },
    { floors: 5, date: "2024-12-27" },
    { floors: 5, date: "2025-01-05" },
    { floors: 5, date: "2025-01-06" },
    { floors: 6, date: "2025-01-10" },
    { floors: 6, date: "2025-01-09" },
    { floors: 6, date: "2025-01-15" },
    { floors: 6, date: "2025-01-16" },
    { floors: 7, date: "2025-01-21" },
    { floors: 7, date: "2025-01-20" },
    { floors: 7, date: "2025-01-26" },
    { floors: 7, date: "2025-01-27" },
    { floors: 8, date: "2025-02-02" },
    { floors: 8, date: "2025-02-02" },
    { floors: 8, date: "2025-02-12" },
    { floors: 8, date: "2025-02-13" },
    { floors: 9, date: "2025-02-18" },
    { floors: 9, date: "2025-02-19" },
    { floors: 9, date: "2025-02-24" },
    { floors: 9, date: "2025-02-25" },
    { floors: 10, date: "2025-02-28" },
  ],
  floors_against_west: [
    { floors: 0, date: "2024-10-15" },
    { floors: 0, date: "2024-11-04" },
    { floors: 0, date: "2024-11-07" },
    { floors: 1, date: "2024-11-09" },
    { floors: 1, date: "2024-11-14" },
    { floors: 1, date: "2024-11-25" },
    { floors: 1, date: "2024-11-26" },
    { floors: 2, date: "2024-11-28" },
    { floors: 2, date: "2024-12-01" },
    { floors: 2, date: "2024-12-06" },
    { floors: 2, date: "2024-12-08" },
    { floors: 3, date: "2024-12-10" },
    { floors: 3, date: "2024-12-10" },
    { floors: 3, date: "2024-12-16" },
    { floors: 3, date: "2024-12-17" },
    { floors: 4, date: "2024-12-22" },
    { floors: 4, date: "2024-12-23" },
    { floors: 4, date: "2024-12-26" },
    { floors: 4, date: "2024-12-27" },
    { floors: 5, date: "2025-01-03" },
    { floors: 5, date: "2025-01-02" },
    { floors: 5, date: "2025-01-08" },
    { floors: 5, date: "2025-01-09" },
    { floors: 6, date: "2025-01-14" },
    { floors: 6, date: "2025-01-14" },
    { floors: 6, date: "2025-01-20" },
    { floors: 6, date: "2025-01-21" },
    { floors: 7, date: "2025-01-26" },
    { floors: 7, date: "2025-01-26" },
    { floors: 7, date: "2025-02-02" },
    { floors: 7, date: "2025-02-03" },
    { floors: 8, date: "2025-02-11" },
    { floors: 8, date: "2025-02-10" },
    { floors: 8, date: "2025-02-16" },
    { floors: 8, date: "2025-02-17" },
    { floors: 9, date: "2025-02-23" },
    { floors: 9, date: "2025-02-24" },
    { floors: 9, date: "2025-02-28" },
    { floors: 9, date: "2025-03-02" },
    { floors: 10, date: "2025-03-07" },
  ],
};

const deviationsData = [
  { floors: -2, openings: 11, walls: 7 },
  { floors: -1, openings: 0, walls: 4 },
  { floors: 0, openings: 12, walls: 8 },
  { floors: 1, openings: 10, walls: 7 },
  { floors: 2, openings: 7, walls: 6 },
  { floors: 3, openings: 9, walls: 8 },
  { floors: 4, openings: 6, walls: 7 },
  { floors: 5, openings: 4, walls: 6 },
  { floors: 6, openings: 6, walls: 5 },
  { floors: 7, openings: 9, walls: 4 },
  { floors: 8, openings: 7, walls: 5 },
  { floors: 9, openings: 5, walls: 4 },
];

const reinforcementData = [
  { floors: -2, east: "N/A", west: "N/A", total: 2.01221897 },
  { floors: -1, east: "N/A", west: "N/A", total: 13.31658291 },
  { floors: 0, east: "N/A", west: "N/A", total: 9.580121019 },
  { floors: 1, east: 15.3356995, west: 41.40197152, total: 25.8409111 },
  { floors: 2, east: 25.67274977, west: 25.15760152, total: 25.32325443 },
  { floors: 3, east: 41.92903548, west: 22.66584767, total: 35.15498434 },
  { floors: 4, east: 30.65666503, west: 25.83796987, total: 28.36591188 },
  { floors: 5, east: 29.73994221, west: 31.49606299, total: 30.58688147 },
  { floors: 6, east: 27.40894674, west: 30.54430949, total: 28.95481009 },
  { floors: 7, east: 29.35637532, west: 28.30136225, total: 29.05576679 },
  { floors: 8, east: 14.17995444, west: 17.08697379, total: 15.89260646 },
  { floors: 9, east: 18.0771445, west: 13.37178815, total: 15.61470911 },
];

const violationsData = [
  { date: "02/11", ppe: 3, noe: 9, ull: 0 },
  { date: "02/12", ppe: 1, noe: 4, ull: 1 },
  { date: "02/13", ppe: 3, noe: 4, ull: 0 },
  { date: "02/14", ppe: 0, noe: 0, ull: 0 },
  { date: "02/15", ppe: 0, noe: 0, ull: 0 },
  { date: "02/16", ppe: 1, noe: 0, ull: 1 },
  { date: "02/17", ppe: 3, noe: 0, ull: 0 },
  { date: "02/18", ppe: 0, noe: 0, ull: 0 },
  { date: "02/19", ppe: 0, noe: 1, ull: 0 },
  { date: "02/20", ppe: 3, noe: 0, ull: 0 },
  { date: "02/21", ppe: 0, noe: 2, ull: 0 },
  { date: "02/22", ppe: 0, noe: 0, ull: 0 },
  { date: "02/23", ppe: 0, noe: 0, ull: 0 },
  { date: "02/24", ppe: 0, noe: 2, ull: 0 },
  { date: "02/25", ppe: 0, noe: 0, ull: 0 },
  { date: "02/26", ppe: 1, noe: 0, ull: 0 },
  { date: "02/27", ppe: 1, noe: 0, ull: 0 },
  { date: "02/28", ppe: 0, noe: 0, ull: 0 },
  { date: "03/01", ppe: 0, noe: 0, ull: 0 },
  { date: "03/02", ppe: 0, noe: 0, ull: 0 },
  { date: "03/03", ppe: 0, noe: 1, ull: 0 },
  { date: "03/04", ppe: 0, noe: 0, ull: 0 },
];

const moneySaved = [
  {
    floors: -2,
    walls: 15750,
    plumbing: 13750,
    reinforcement: 6000,
    total: 41500,
  },
  { floors: -1, walls: 9000, plumbing: 0, reinforcement: 16500, total: 31500 },
  {
    floors: 0,
    walls: 18000,
    plumbing: 15000,
    reinforcement: 16500,
    total: 55500,
  },
  {
    floors: 1,
    walls: 15750,
    plumbing: 12500,
    reinforcement: 10500,
    total: 44750,
  },
  {
    floors: 2,
    walls: 13500,
    plumbing: 8750,
    reinforcement: 9000,
    total: 37250,
  },
  {
    floors: 3,
    walls: 18000,
    plumbing: 11250,
    reinforcement: 15000,
    total: 50250,
  },
  {
    floors: 4,
    walls: 15750,
    plumbing: 7500,
    reinforcement: 12000,
    total: 41250,
  },
  {
    floors: 5,
    walls: 13500,
    plumbing: 5000,
    reinforcement: 13500,
    total: 38000,
  },
  {
    floors: 6,
    walls: 11250,
    plumbing: 7500,
    reinforcement: 10500,
    total: 35250,
  },
  {
    floors: 7,
    walls: 9000,
    plumbing: 11250,
    reinforcement: 9000,
    total: 35250,
  },
  {
    floors: 8,
    walls: 11250,
    plumbing: 8750,
    reinforcement: 7500,
    total: 33500,
  },
  { floors: 9, walls: 9000, plumbing: 6250, reinforcement: 6000, total: 27250 },
];

const Container = styled("div", {
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
});

const DashboardContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr",
  gap: "16px",
  width: "100%",
  height: "100vh",
  padding: "16px",
  backgroundColor: "#f5f5f5",
  fontFamily: "Arial, sans-serif",
});

const ChartCard = styled("div", {
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "15px",
  border: "1px solid #A0A4C7",
  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
});

const ChartTitleCard = styled(Flex, {
  borderRadius: "14px",
  border: "1px solid #E6EBF9",
  background: "#FFF",
  boxShadow: "1px 1px 1px 0px rgba(153, 153, 153, 0.62)",
  padding: "15px",
  marginBottom: "20px",
});

const ChartContainer = styled("div", {
  // flex: "1",
  // minHeight: "0",
  height: 400,
});

const ExpandIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M13.125 13.125L18.375 18.375M18.375 18.375V14.175M18.375 18.375H14.175M13.125 7.875L18.375 2.625M18.375 2.625V6.825M18.375 2.625H14.175M2.625 14.175V18.375M2.625 18.375H6.825M2.625 18.375L7.875 13.125M2.625 6.825V2.625M2.625 2.625H6.825M2.625 2.625L7.875 7.875"
      stroke="#4C5393"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const FullscreenOverlay = styled("div", {
  position: "fixed",
  top: 10,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  padding: "20px",
  boxSizing: "border-box",
});

const FullscreenChartCard = styled(ChartCard, {
  width: "90%",
  height: "90%",
  maxWidth: "1200px",
  maxHeight: "800px",
});

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#4C5393"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

function timestampToDDMM(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  return `${day}/${month}`;
}

const transformData = (data) => {
  return data
    .filter((item) => item.date !== null)
    .map((item) => ({
      ...item,
      timestamp: new Date(item.date).getTime(), // Convert to timestamp
    }));
};

const Analytics = () => {
  const { selectedProject } = useAppContext();

  const [executionSwitch, setExecutionSwitch] = useState({
    designed: true,
    west: true,
    east: true,
  });

  const [deviationSwitch, setDeviationSwitch] = useState({
    walls: true,
    openings: true,
    reinforcement: false,
  });

  const [safetySwitch, setSafetySwitch] = useState({
    ppe: true,
    noe: true,
    ull: true,
  });

  const [moneySwitch, setMoneySwitch] = useState({
    walls: true,
    plumbing: true,
    reinforcement: true,
    total: true,
  });

  // New state for fullscreen management
  const [fullscreenChart, setFullscreenChart] = useState(null);

  const handleExecutionChange = (values) => {
    const updatedValues = Object.fromEntries(
      Object.keys(executionSwitch).map((key) => [key, values.includes(key)])
    );
    setExecutionSwitch(updatedValues);
  };

  const handleDeviationChange = (values) => {
    let updatedValues = {};

    // Case 1: If reinforcement is newly selected (wasn't previously selected)
    if (values.includes("reinforcement") && !deviationSwitch.reinforcement) {
      updatedValues = Object.fromEntries(
        Object.keys(deviationSwitch).map((key) => [
          key,
          key === "reinforcement",
        ])
      );
    }
    // Case 2: If any non-reinforcement option is selected
    else if (values.some((value) => value !== "reinforcement")) {
      updatedValues = Object.fromEntries(
        Object.keys(deviationSwitch).map((key) => [
          key,
          key !== "reinforcement" && values.includes(key),
        ])
      );
    }
    // Case 3: No options selected or only reinforcement is selected (and was already selected)
    else {
      updatedValues = Object.fromEntries(
        Object.keys(deviationSwitch).map((key) => [key, values.includes(key)])
      );
    }
    setDeviationSwitch(updatedValues);
  };

  const handleSafetyChange = (values) => {
    const updatedValues = Object.fromEntries(
      Object.keys(safetySwitch).map((key) => [key, values.includes(key)])
    );
    setSafetySwitch(updatedValues);
  };

  const handleMoneyChange = (values) => {
    const updatedValues = Object.fromEntries(
      Object.keys(moneySwitch).map((key) => [key, values.includes(key)])
    );
    setMoneySwitch(updatedValues);
  };

  // Function to toggle fullscreen for a specific chart
  const toggleFullscreen = (chartName) => {
    setFullscreenChart(fullscreenChart === chartName ? null : chartName);
  };

  // Render chart based on name with fullscreen capability
  const renderChart = (chartName, chartContent) => (
    <ChartCard>
      <ChartTitleCard align="center" justify="between">
        {chartContent.titleContent}
        <div
          onClick={() => toggleFullscreen(chartName)}
          style={{ cursor: "pointer" }}
        >
          <ExpandIcon />
        </div>
      </ChartTitleCard>
      <ChartContainer>{chartContent.chart}</ChartContainer>

      {/* Fullscreen Overlay */}
      {fullscreenChart === chartName && (
        <FullscreenOverlay onClick={() => toggleFullscreen(chartName)}>
          <FullscreenChartCard onClick={(e) => e.stopPropagation()}>
            <ChartTitleCard align="center" justify="between">
              {chartContent.titleContent}
              <div
                onClick={() => toggleFullscreen(chartName)}
                style={{ cursor: "pointer" }}
              >
                <CloseIcon />
              </div>
            </ChartTitleCard>
            <ChartContainer>{chartContent.chart}</ChartContainer>
          </FullscreenChartCard>
        </FullscreenOverlay>
      )}
    </ChartCard>
  );

  // Prepare chart contents
  const chartContents = {
    executionTime: {
      titleContent: (
        <Flex align="center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="#006AFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Text size="4" weight="bold" ml="12px">
            Execution Time
          </Text>
          <CheckboxGroup.Root
            defaultValue="1"
            ml="12px"
            value={Object.keys(executionSwitch).filter(
              (key) => executionSwitch[key]
            )}
            onValueChange={handleExecutionChange}
          >
            <Flex gapX="10px">
              <CheckboxGroup.Item value="east">East</CheckboxGroup.Item>
              <CheckboxGroup.Item value="west">West</CheckboxGroup.Item>
              <CheckboxGroup.Item value="designed">Designed</CheckboxGroup.Item>
            </Flex>
          </CheckboxGroup.Root>
        </Flex>
      ),
      chart: (
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis
              dataKey="timestamp"
              type="number"
              domain={["dataMin", "dataMax"]}
              tickFormatter={(timestamp) => timestampToDDMM(timestamp)}
              label={{
                value: "Date",
                position: "insideBottomRight",
                offset: -10,
              }}
              tickCount={8}
            />
            <YAxis
              dataKey="floors"
              label={{
                value: "Floor",
                angle: -90,
                position: "center",
                dx: -20,
              }}
              domain={["dataMin", "dataMax"]}
              ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            />
            <Tooltip
              labelFormatter={(timestamp) =>
                new Date(timestamp).toLocaleDateString()
              }
              formatter={(value, name, props) => [
                name === "timestamp"
                  ? new Date(value).toLocaleDateString()
                  : value,
                name === "timestamp" ? "Date" : "Floors",
              ]}
            />
            <Legend iconType="rect" />
            {executionSwitch.designed && (
              <Scatter
                name="Designed"
                data={transformData(floorsData["floors_against_designed"])}
                fill="#9794FF"
                line
                shape="circle"
              />
            )}
            {executionSwitch.east && (
              <Scatter
                name="East"
                data={transformData(floorsData["floors_against_east"])}
                fill="#4C5393"
                line
                shape="circle"
              />
            )}
            {executionSwitch.west && (
              <Scatter
                name="West"
                data={transformData(floorsData["floors_against_west"])}
                fill="#D1C4E9"
                line
                shape="circle"
              />
            )}
          </ScatterChart>
        </ResponsiveContainer>
      ),
    },
    nonCompliances: {
      titleContent: (
        <Flex align="center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M4.01778 4.05611L17.6785 17.7169M20.4684 10.8865C20.4684 16.1996 16.1613 20.5067 10.8482 20.5067C5.53504 20.5067 1.22791 16.1996 1.22791 10.8865C1.22791 5.57337 5.53504 1.26624 10.8482 1.26624C16.1613 1.26624 20.4684 5.57337 20.4684 10.8865Z"
              stroke="#FF5454"
              strokeWidth="2.27446"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Text size="4" weight="bold" ml="12px">
            Non-compliances
          </Text>
          <CheckboxGroup.Root
            ml="12px"
            value={Object.keys(deviationSwitch).filter(
              (key) => deviationSwitch[key]
            )}
            onValueChange={handleDeviationChange}
          >
            <Flex gapX="10px">
              <CheckboxGroup.Item value="walls">Walls</CheckboxGroup.Item>
              <CheckboxGroup.Item value="openings">Openings</CheckboxGroup.Item>
              <CheckboxGroup.Item value="reinforcement">
                Reinforcement
              </CheckboxGroup.Item>
            </Flex>
          </CheckboxGroup.Root>
        </Flex>
      ),
      chart: (
        <>
          {!deviationSwitch.reinforcement ? (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={deviationsData}
                margin={{ top: 20, right: 30, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="floors"
                  label={{
                    value: "Floor",
                    position: "insideBottomRight",
                    offset: -10,
                  }}
                />
                <YAxis
                  label={{
                    value: "Number of errors",
                    angle: -90,
                    position: "center",
                    dx: -20,
                  }}
                />
                <Tooltip />
                <Legend />
                {deviationSwitch.walls && (
                  <Bar dataKey="walls" stackId="a" fill="#9794FF" />
                )}
                {deviationSwitch.openings && (
                  <Bar dataKey="openings" stackId="a" fill="#5C57F7" />
                )}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={reinforcementData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
                <XAxis
                  dataKey="floors"
                  label={{
                    value: "Floor",
                    position: "insideBottomRight",
                    offset: -10,
                  }}
                />
                <YAxis
                  label={{
                    value: "As-Built / Designed weight [%]",
                    angle: -90,
                    position: "center",
                    dx: -20,
                  }}
                  tickCount={10}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="east" fill="#5C57F7" />
                <Bar dataKey="west" fill="#4C5393" />
                <Bar dataKey="total" fill="#9794FF" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </>
      ),
    },
    safetyViolations: {
      titleContent: (
        <Flex align="center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.5 9.49996L9.5 14.5M9.5 9.49996L14.5 14.5M20 13C20 18 16.5 20.5 12.34 21.95C12.1222 22.0238 11.8855 22.0202 11.67 21.94C7.5 20.5 4 18 4 13V5.99996C4 5.73474 4.10536 5.48039 4.29289 5.29285C4.48043 5.10532 4.73478 4.99996 5 4.99996C7 4.99996 9.5 3.79996 11.24 2.27996C11.4519 2.09896 11.7214 1.99951 12 1.99951C12.2786 1.99951 12.5481 2.09896 12.76 2.27996C14.51 3.80996 17 4.99996 19 4.99996C19.2652 4.99996 19.5196 5.10532 19.7071 5.29285C19.8946 5.48039 20 5.73474 20 5.99996V13Z"
              stroke="#FF5454"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Text size="4" weight="bold" ml="12px">
            Safety Violations
          </Text>
          <CheckboxGroup.Root
            ml="12px"
            value={Object.keys(safetySwitch).filter((key) => safetySwitch[key])}
            onValueChange={handleSafetyChange}
          >
            <Flex gapX="10px">
              <CheckboxGroup.Item value="ppe">PPE</CheckboxGroup.Item>
              <CheckboxGroup.Item value="noe">
                Near Open Edge
              </CheckboxGroup.Item>
              <CheckboxGroup.Item value="ull">
                Under Load Lift
              </CheckboxGroup.Item>
            </Flex>
          </CheckboxGroup.Root>
        </Flex>
      ),
      chart: (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={violationsData}
            margin={{ top: 20, right: 30, left: 10, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{
                value: "Date",
                position: "insideBottomRight",
                offset: -10,
              }}
            />
            <YAxis
              label={{
                value: "Violations Number",
                angle: -90,
                position: "center",
                dx: -20,
              }}
            />
            <Tooltip />
            <Legend wrapperStyle={{ textTransform: "uppercase" }} />
            {safetySwitch.ppe && (
              <Bar dataKey="ppe" stackId="a" fill="#9794FF" />
            )}
            {safetySwitch.noe && (
              <Bar dataKey="noe" stackId="a" fill="#5C57F7" />
            )}
            {safetySwitch.ull && (
              <Bar dataKey="ull" stackId="a" fill="#4C5393" />
            )}
          </BarChart>
        </ResponsiveContainer>
      ),
    },
    moneySaved: {
      titleContent: (
        <Flex align="center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M11.8867 16.3H14.0837C14.6664 16.3 15.2253 16.0682 15.6373 15.6556C16.0493 15.2431 16.2808 14.6835 16.2808 14.1C16.2808 13.5165 16.0493 12.9569 15.6373 12.5444C15.2253 12.1318 14.6664 11.9 14.0837 11.9H10.7882C10.1291 11.9 9.5798 12.12 9.25024 12.56L3.09852 18.5M7.49261 22.9L9.25024 21.36C9.5798 20.92 10.1291 20.7 10.7882 20.7H15.1823C16.3906 20.7 17.4892 20.26 18.2581 19.38L23.3113 14.54C23.7352 14.1389 23.9826 13.5855 23.9991 13.0017C24.0156 12.4179 23.7998 11.8515 23.3992 11.427C22.9986 11.0025 22.446 10.7548 21.863 10.7383C21.28 10.7218 20.7143 10.9379 20.2904 11.339L15.6766 15.629M2 17.4L8.59113 24M20.565 9.7C20.565 11.4618 19.1387 12.89 17.3793 12.89C15.6199 12.89 14.1936 11.4618 14.1936 9.7C14.1936 7.93821 15.6199 6.51 17.3793 6.51C19.1387 6.51 20.565 7.93821 20.565 9.7ZM9.68965 5.3C9.68965 7.12254 8.21418 8.6 6.39409 8.6C4.574 8.6 3.09852 7.12254 3.09852 5.3C3.09852 3.47746 4.574 2 6.39409 2C8.21418 2 9.68965 3.47746 9.68965 5.3Z"
              stroke="#0ED907"
              strokeWidth="2.24603"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Text size="4" weight="bold" ml="12px" mr="80px">
            Money Saved
          </Text>

          <FilterSelect
            selectedItems={Object.keys(moneySwitch).filter(
              (key) => moneySwitch[key]
            )}
            options={["walls", "plumbing", "reinforcement", "total"]}
            onSelect={(vals) => handleMoneyChange(vals)}
            variant="light"
          />
        </Flex>
      ),
      chart: (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={moneySaved}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
            <XAxis
              dataKey="floors"
              label={{
                value: "Floor",
                position: "insideBottomRight",
                offset: -10,
              }}
            />
            <YAxis
              label={{
                value: "NIS [₪]",
                angle: -90,
                position: "center",
                dx: -40,
              }}
            />
            <Tooltip />
            <Legend />
            {moneySwitch.walls && <Bar dataKey="walls" fill="#5C57F7" />}
            {moneySwitch.plumbing && <Bar dataKey="plumbing" fill="#4C5393" />}
            {moneySwitch.reinforcement && (
              <Bar dataKey="reinforcement" fill="#9794FF" />
            )}
            {moneySwitch.total && <Bar dataKey="total" fill="#D1C4E9" />}
          </BarChart>
        </ResponsiveContainer>
      ),
    },
  };

  if (selectedProject?.name !== "Ibn Shaprut 3-7") {
    return (
      <Container style={{ height: "100vh" }}>
        <Flex direction="column" align="center" justify="center">
          <Text size="4" weight="bold" color="violet">
            No graph data for this project
          </Text>
          <Text size="2" mt="2" color="violet">
            Can't preview dashboard analytics page because there is no available
            data yet for this project{" "}
          </Text>
        </Flex>
      </Container>
    );
  }

  return (
    <DashboardContainer>
      {renderChart("executionTime", chartContents.executionTime)}
      {renderChart("nonCompliances", chartContents.nonCompliances)}
      {renderChart("safetyViolations", chartContents.safetyViolations)}
      {renderChart("moneySaved", chartContents.moneySaved)}
    </DashboardContainer>
  );
};

export default Analytics;

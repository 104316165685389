import React, { useEffect, useState } from "react";
import { Box, Text, Flex, TextField, Button } from "@radix-ui/themes";
import Logo from "./atom-logo.jpg";
import { toast } from "react-toastify";
import { LuUser2 } from "react-icons/lu";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api/api";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const notifyError = (msg) => toast.error(msg);

  const handleChange = (e) => {
    const data = e.target.value;
    setEmail(data);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await api.post("/auth/forgot-password", { email });
      setSuccess(true);
    } catch (err) {
      notifyError(err.response.data?.error ?? "Error sending mail");
    }
  };

  useEffect(() => {
    const urlEmail = searchParams.get("email");
    setEmail(urlEmail);
  }, []);

  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Box
        style={{
          background: "#F8F8F8",
          border: "1px solid #A5A8A6",
          borderRadius: 10,
          padding: 60,
        }}
        width={{
          lg: 800,
          initial: "100vw",
        }}
        height={{
          lg: 670,
          initial: "100vh",
        }}
      >
        {success ? (
          <Box>
            <Flex justify="end">
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex direction="column" justify="center" align="center" mt="70px">
              <Text size="5" weight="bold" align="center">
                Check your mail box
              </Text>
              <Text size="3" align="center" mt="10px">
                We sent you an email with a link to reset your password
              </Text>
              <Button
                radius="full"
                size="4"
                style={{ width: "200px" }}
                mt="60px"
                onClick={() => navigate("/login")}
              >
                Back to login
              </Button>
            </Flex>
          </Box>
        ) : (
          <form onSubmit={handleForgotPassword}>
            <Flex justify="end" align="center" gapX="280px">
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify="center"
              align="center"
              gapX="30px"
              mt="80px"
              direction="column"
            >
              <Box minWidth="320px">
                <TextField.Root
                  type="email"
                  placeholder="Enter your work email"
                  size="3"
                  name="email"
                  value={email}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuUser2 height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
              <Button
                radius="full"
                size="3"
                style={{ width: "200px" }}
                type="submit"
                mt="60px"
              >
                Send mail
              </Button>
            </Flex>
          </form>
        )}
      </Box>
    </Flex>
  );
};

export default ForgetPassword;

import { Box, Button, Flex, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import colors from "./colors";
import FilterIcon from "../icons/filter.svg";
import ReportIcon from "../icons/report.svg";
import NoReportIcon from "../icons/no-report.svg";
import NoReportIconSmall from "../icons/no-report-small.svg";
import { FaTimes } from "react-icons/fa";
import api from "../api/api";
import {
  extractReportNumber,
  getFileNameFromPath,
  getOriginalFilename,
} from "../utils";
import { useAppContext } from "../context/appContext";
import FilterSelect from "./FilterSelect";
import MultiDateSelector from "./MultipleDateSelect";
import { defaultElements, defaultObjects } from "../utils/constants";
import { request } from "../api/request";
import {
  BsArrowDown,
  BsArrowUp,
  BsCheckLg,
  BsQuestionLg,
  BsDownload,
} from "react-icons/bs";
import { useSearchParams } from "react-router-dom";

const bucketName = "atom-construction-bucket-eu";

const Container = styled(Box, {
  background: colors.background,
  padding: "25px",
});

const PDFContainer = styled(Flex, {
  padding: "60px 10px 60px 10px",
  flexGrow: 1,
  borderRadius: "5px",
  background: "#FFF",
  boxShadow: "0px 4px 10px 0px #D9D9D9",
});

const ControlContainer = styled(Box, {
  padding: "25px",
  width: "400px",
  borderRadius: "10px",
  background: "#F5F6FA",
  boxShadow: "0px 4px 6px 0px #D9D9D9",
});

const ReportItemContainer = styled(Box, {
  height: "50vh",
  overflowY: "scroll",
});

const FilterItem = styled(Flex, {
  columnGap: "15px",
});

const RawButton = styled(Button, {
  backgroundColor: "transparent !important",
  color: "inherit !important",
  cursor: "pointer",
});

const NoReportContainer = styled(Flex, {
  padding: "10px",
  borderRadius: "5px",
  background: "#fff",
  boxShadow: "0px 4px 6px 0px rgba(183, 181, 188, 0.12)",
  flexDirection: "column",
});

const StyledReportItem = styled(Flex, {
  background: "#fff",
  borderRadius: "5px",
  padding: "10px",
  color: colors.active,
  marginTop: "10px",
  variants: {
    active: {
      true: {
        backgroundColor: colors.active,
        color: "#fff",
      },
      false: {
        backgroundColor: "#fff",
        color: colors.active,
      },
    },
  },
});

const pdfFileUrl = "https://pdfobject.com/pdf/sample.pdf";

const ReportItem = ({ report, onView, active }) => {
  const name = getFileNameFromPath(report.url ?? "");

  const downloadReport = async () => {
    try {
      const response = await api.get(`/report/${report.id}/presigned`);
      const downloadUrl = response.data.downloadUrl;

      const fileResponse = await fetch(downloadUrl);
      const blob = await fileResponse.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const viewReport = async () => {
    try {
      const response = await api.get(`/report/${report.id}/presigned`);
      onView(report, response.data.downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <StyledReportItem justify="between" align="center" active={active}>
      <Text size="2">{name}</Text>
      <Flex align="center" gap="2">
        <BsDownload
          size={16}
          style={{
            color: active ? "#fff" : colors.active,
            cursor: "pointer",
          }}
          onClick={downloadReport}
        />
        <RawButton onClick={() => viewReport()}>
          {active ? "Viewing" : "Open"}
        </RawButton>
      </Flex>
    </StyledReportItem>
  );
};

const Card = styled(Flex, {
  width: "140px",
  height: "80px",
  borderRadius: "6px",
  boxShadow: "0px 1.216px 1.216px 0px #DADADA",
  color: "White",
  cursor: "pointer",

  variants: {
    variant: {
      coral: {
        backgroundColor: "#FF8C7C",
      },
      blue: {
        backgroundColor: "#4B6BDF",
      },
      green: {
        backgroundColor: "#86EFAC",
      },
      red: {
        backgroundColor: "Tomato",
      },
    },
  },
});
export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const parseDate = (dateString) => new Date(dateString + "T00:00:00");

const SightInsights = () => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { selectedProject } = useAppContext();
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [allowedDates, setAllowedDates] = useState([]);
  const [floors, setFloors] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSummary, setCurrentSummary] = useState(null);

  const searchPluginInstance = searchPlugin();
  const { highlight } = searchPluginInstance;

  const handleSearch = (keyword) => {
    highlight([keyword]);
  };

  const getReports = async () => {
    try {
      const params = {
        project: selectedProject?.name,
        date: searchParams.getAll("date"),
        floor: searchParams.getAll("floor"),
        element: searchParams.getAll("element"),
        object: searchParams.getAll("object"),
      };

      const response = await api.get("/report", { params });
      const reports = response.data.reports;

      if (reports.length > 0 && !selectedReport) {
        const presignedResponse = await api.get(
          `/report/${reports[0].id}/presigned`
        );
        setSelectedReport({
          ...reports[0],
          url: presignedResponse.data.downloadUrl,
        });
        fetchSummary(reports[0]);
      }

      setReports(reports);
      if (allowedDates.length === 0) {
        setAllowedDates(
          reports
            .map((report) => report.scannedAt)
            .filter((date) => date)
            .map((date) => new Date(date)) // Extract only the date part
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilters = (filterType, values) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(filterType);

    values.forEach((value) => {
      newSearchParams.append(filterType, value);
    });
    setSearchParams(newSearchParams);
  };

  const resetFilters = () => {
    setSearchParams({});
  };

  const onView = (report, url) => {
    setCurrentSummary(null);
    setSelectedReport({ ...report, url });
    fetchSummary(report);
  };

  const fetchFloors = async () => {
    try {
      const response = await request.get("/api/folder/list-floors", {
        params: {
          bucketName,
          client: selectedProject?.client,
          project: selectedProject?.name,
        },
      });
      setFloors(response.data.floors);
    } catch (error) {
      console.error("Error fetching floors", error);
    }
  };

  const fetchSummary = async (report) => {
    const path = `clients/${selectedProject.client}/${selectedProject.name}/${report.floor}/${report.element}/${report.object}`;
    const reportNumber = extractReportNumber(report.url);
    const reportName = `Report${reportNumber}_Summary.json`;
    try {
      const response = await api.get(
        `/report/summary?pathPrefix=${path}&reportName=${reportName}`
      );
      setCurrentSummary(response.data.data);
    } catch (err) {
      console.log("failed to fetch summary");
    }
  };

  useEffect(() => {
    getReports();
  }, [searchParams, selectedProject]);

  useEffect(() => {
    fetchFloors();
    setSelectedReport(null);
  }, [selectedProject?.id]);

  const hasReports = reports.length > 0;
  const hasFilters = Array.from(searchParams.entries()).length > 1;

  const uniqueReportsByName = Array.from(
    new Map(
      reports.map((report) => [
        getFileNameFromPath(report.url),
        { ...report, name: getFileNameFromPath(report.url) },
      ])
    ).values()
  );

  const getColorMap = (summary) => {
    const colorMap = {
      rebars: summary.rebars > 0 ? "blue" : "red",
      meshes: summary.meshes > 0 ? "blue" : "red",
      openings: summary.openings ? "green" : "coral",
      walls: summary.walls ? "green" : "coral",
    };
    return colorMap;
  };

  return (
    <Container>
      <Text color="violet" size="4">
        Site Insights
      </Text>
      {selectedReport && currentSummary && (
        <Flex justify="start" align="center" mt="10px" mb="10px" gapX="20px">
          {currentSummary.rebars && (
            <Card
              variant={getColorMap(currentSummary)["rebars"]}
              justify="center"
              align="center"
              onClick={() => handleSearch("3.1. Sets of bottom")}
            >
              {currentSummary.rebars > 0 ? (
                <BsArrowUp size="35px" />
              ) : (
                <BsArrowDown size="35px" />
              )}
              <Flex direction="column" align="center" ml="5px">
                <Text size="4" weight="bold">
                  {currentSummary.rebars}%
                </Text>
                <Text size="2" weight="bold">
                  Rebars
                </Text>
              </Flex>
            </Card>
          )}
          {currentSummary.meshes && (
            <Card
              variant={getColorMap(currentSummary)["meshes"]}
              justify="center"
              align="center"
              onClick={() => handleSearch("3.2. Sets of bottom")}
            >
              {currentSummary.meshes > 0 ? (
                <BsArrowUp size="35px" />
              ) : (
                <BsArrowDown size="35px" />
              )}
              <Flex direction="column" align="center" ml="5px">
                <Text size="4" weight="bold">
                  {currentSummary.meshes}%
                </Text>
                <Text size="2" weight="bold">
                  Meshes
                </Text>
              </Flex>
            </Card>
          )}
          {selectedReport?.object === "bottom-reinforcement" && (
            <Card
              variant={getColorMap(currentSummary)["walls"]}
              justify="center"
              align="center"
              onClick={() => handleSearch("Built walls")}
            >
              <Flex direction="column" align="center" ml="5px">
                {currentSummary.walls ? (
                  <BsCheckLg size="45px" />
                ) : (
                  <BsQuestionLg size="45px" />
                )}
                <Text size="2" weight="bold">
                  Walls
                </Text>
              </Flex>
            </Card>
          )}
          {selectedReport?.object === "bottom-reinforcement" && (
            <Card
              variant={getColorMap(currentSummary)["openings"]}
              justify="center"
              align="center"
              onClick={() => handleSearch("Built openings")}
            >
              <Flex direction="column" align="center" ml="5px">
                {currentSummary.openings ? (
                  <BsCheckLg size="45px" />
                ) : (
                  <BsQuestionLg size="45px" />
                )}
                <Text size="2" weight="bold">
                  Openings
                </Text>
              </Flex>
            </Card>
          )}
        </Flex>
      )}

      <Flex mt="15px" gapX="25px" height="100vh">
        {selectedReport ? (
          <PDFContainer direction="column" align="center" justify="center">
            <Text
              color="violet"
              size="3"
              weight="bold"
              align="center"
              mb="10px"
            >
              {getOriginalFilename(selectedReport?.url ?? "")}
            </Text>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={selectedReport?.url}
                plugins={[zoomPluginInstance, searchPluginInstance]}
              />
            </Worker>
            <Flex gapX="10px" mt="10px">
              <ZoomOutButton />
              <ZoomInButton />
            </Flex>
          </PDFContainer>
        ) : (
          <PDFContainer direction="column" align="center" justify="center">
            <img src={NoReportIcon} alt="no report icon" />
            <Text size="3" color="violet" weight="bold" mt="30px">
              No report to view
            </Text>
            <Text size="2" color="violet" mt="10px">
              Please verify selection or refresh page
            </Text>
          </PDFContainer>
        )}

        <ControlContainer>
          <Flex gapX="25px" align="center">
            <img src={FilterIcon} alt="filter icon" />
            <Text size="3" weight="bold" color="violet">
              Apply filters
            </Text>
          </Flex>
          <Box mt="15px">
            <FilterItem>
              <Flex direction="column" style={{ width: "160px" }}>
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Date
                </Text>
                <MultiDateSelector
                  selectedDates={searchParams.getAll("date").map(parseDate)}
                  allowedDates={allowedDates}
                  onSelect={(dates) =>
                    updateFilters(
                      "date",
                      dates.map((d) => formatDate(d))
                    )
                  }
                />
              </Flex>
              <Flex direction="column">
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Floor
                </Text>
                <FilterSelect
                  selectedItems={searchParams.getAll("floor")}
                  options={floors}
                  onSelect={(floors) => updateFilters("floor", floors)}
                />
              </Flex>
            </FilterItem>

            <FilterItem mt="15px">
              <Flex direction="column">
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Element
                </Text>
                <FilterSelect
                  selectedItems={searchParams.getAll("element")}
                  options={defaultElements}
                  onSelect={(elements) => updateFilters("element", elements)}
                />
              </Flex>
              <Flex direction="column">
                <Text size="2" color="violet" weight="bold" mb="5px">
                  Object
                </Text>
                <FilterSelect
                  selectedItems={searchParams.getAll("object")}
                  options={defaultObjects}
                  onSelect={(objects) => updateFilters("object", objects)}
                />
              </Flex>
            </FilterItem>

            {hasFilters && (
              <Button size="1" mt="10px" variant="soft" onClick={resetFilters}>
                reset <FaTimes />
              </Button>
            )}
          </Box>

          <Box mt="50px">
            <Flex gapX="15px" align="center" mb="20px">
              <img src={ReportIcon} alt="report icon" />
              <Text size="3" weight="bold" color="violet">
                Reports found
              </Text>
            </Flex>
            <ReportItemContainer>
              {hasReports ? (
                uniqueReportsByName.map((report) => (
                  <ReportItem
                    key={report?.id}
                    report={report}
                    onView={onView}
                    active={selectedReport?.id === report?.id}
                  />
                ))
              ) : (
                <NoReportContainer justify="center" align="center">
                  <Box style={{ width: "25px", height: "25px" }}>
                    <img src={NoReportIconSmall} alt="no report icon" />
                  </Box>
                  <Text size="3" color="violet" weight="bold" mt="20px">
                    No report to view
                  </Text>
                  <Text size="2" color="violet" mt="10px">
                    Please verify selection or refresh page
                  </Text>
                </NoReportContainer>
              )}
            </ReportItemContainer>
          </Box>
        </ControlContainer>
      </Flex>
    </Container>
  );
};

export default SightInsights;

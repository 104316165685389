import axios from "axios";

const isLocalhost = window.location.hostname === "localhost";

export const request = axios.create({
  baseURL: window.location.href.includes("https://localhost")
    ? "https://localhost:5002"
    : isLocalhost
    ? "http://localhost:5002"
    : "https://web.atom.construction",
});

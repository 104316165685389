import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import FlyingEye from "./components/FlyingEye";
import ForgetPassword from "./components/ForgetPassword";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./components/ResetPassword";
import Signup from "./components/Signup";
import SightInsights from "./components/SiteInsights";
import Settings from "./components/Settings";
import { AppProvider } from "./context/appContext";
import EyalSafety from "./components/EyalSafety";
import Viewer from "./components/DWGViewer/viewer";
import LinkAutodeskAccount from "./components/LinkAutodeskAccount";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Analytics from "./components/Analytics";

// const isLoggedIn = () => {
//   return !!localStorage.getItem("accessToken");
// };

const App = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isLoggedIn()) {
  //     // Redirect to dashboard if user is logged in
  //     navigate("/dashboard");
  //   }
  // }, []);
  const trackPageView = (url) => {
    window.gtag("config", "G-TTR7W6G8T4", { page_path: url });
  };
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      {/* Protect dashboard route */}
      <Route element={<PrivateRoute />}>
        <Route
          path="dashboard/*"
          element={
            <AppProvider>
              <Dashboard />
            </AppProvider>
          }
        >
          {/* Nested routes under dashboard */}
          <Route path="" element={<Analytics />} />
          <Route path="camera" element={<FlyingEye />} />
          <Route path="insights" element={<SightInsights />} />
          <Route path="settings" element={<Settings />} />
          <Route path="eyal-safety" element={<EyalSafety />} />
          <Route path="progress-tracking" element={<Viewer />} />
          <Route path="link-acc-account" element={<LinkAutodeskAccount />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;

import React, { useState } from "react";
import {
  Box,
  Button,
  Callout,
  Dialog,
  Flex,
  Select,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import api from "../api/api";
import { toast } from "react-toastify";
import { isValidDurationAndDate } from "../utils";
import {useAppContext} from "../context/appContext";

const bucketName = "atom-construction-bucket-eu";

const RequestRecordModal = ({ open, toggleOpen, cameras, selectedProject }) => {

  const [checkingStatus, setCheckingStatus] = useState(false);
  const [cameraAlive, setIsCameraAlive] = useState(true);
  const [validDuration, setValidDuration] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    cameraId: null,
    date: null,
    startTime: null,
    endTime: null,
  });

  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  const checkCameraStatus = async (camera) => {
    setCheckingStatus(true);
    try {
      const response = await api.get(`/camera/check-camera/${camera.id}`);
      // debugger;
      if (response.status === 200) {
        const { isAliveIp2, isAliveIp1, ip1, ip2 } = response.data;
        // Set active IP based on priority
        const isAlive=isAliveIp1||isAliveIp2;
        setIsCameraAlive(isAlive);
        if(!isAlive)notifyError(`${camera.name} is down, can't generate recordings`);
      } else {
        setIsCameraAlive(false);
        notifyError(`${camera.name} is down, can't generate recordings`);
      }
      setCheckingStatus(false);
    } catch (error) {
      setCheckingStatus(false);
      setIsCameraAlive(false);
      notifyError(`${camera.name} is down, can't generate recordings`);
    }
  };

  const generateRecordings = async () => {
    const payload = {
      projectId:selectedProject?.id,
      client: selectedProject?.client,
      project: selectedProject?.name,
      bucket_name: bucketName,
      date:formData.date,
      start_time: new Date(`${formData.date}T${formData.startTime}:00`),
      end_time: new Date(`${formData.date}T${formData.endTime}:00`),
      message:message
    };
    debugger;
    setGenerating(true);

    try {
      await api.post(`/camera/${selectedCamera.id}/recordings`, payload);
      setGenerating(false);
      notifySuccess("Recordings generated successfully");
    } catch (err) {
      setGenerating(false);
      notifyError(`An error occured whiles generating recordings`);
    }
  };

  const handleOnEndtime = (val) => {
    setFormData((data) => ({ ...data, endTime: val }));
    const { isValid, message } = isValidDurationAndDate({
      ...formData,
      endTime: val,
    });
    if (isValid) {
      setValidDuration(true);
    } else {
      setValidDuration(false);
      notifyError(message);
    }
  };

  const handleCameraChange = (cameraId) => {
    setFormData((data) => ({ ...data, cameraId }));
    const camera = cameras.find((camera) => camera.id === cameraId);
    setSelectedCamera(camera);
    checkCameraStatus(camera);
  };

  const isDisabled = checkingStatus || !cameraAlive || !validDuration || !formData.startTime || !formData.endTime;

  return (
    <Dialog.Root open={open} onOpenChange={toggleOpen}>
      <Dialog.Content
        maxWidth="600px"
        maxHeight="600px"
        style={{ padding: "40px" }}
      >
        <Flex justify="center" align="center" direction="column">
          <Flex align="center">
            <Text size="3" weight="bold">
              Request For Recording
            </Text>
          </Flex>
          <Box mt="40px">
            <Box>
              <Select.Root
                size="3"
                name="camera"
                placeholder="Select Camera"
                value={formData.cameraId ?? ""}
                onValueChange={handleCameraChange}
              >
                <Select.Trigger
                  style={{ width: "100%" }}
                  placeholder="Select Camera"
                  required
                />
                <Select.Content style={{ width: "100%" }}>
                  {cameras?.map((camera) => (
                    <Select.Item value={camera.id} key={camera.id}>
                      {camera.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Box>
            <Box mt="10px">
              <DatePicker
                value={formData.date}
                onChange={(val) =>
                  setFormData((data) => ({ ...data, date: val }))
                }
                label="Choose date"
              />
            </Box>
            <Flex align="center" gapx="15px" mt="10px">
              <Box width={"100%"}>
                <TimePicker
                  value={formData.startTime}
                  onChange={(val) =>
                    setFormData((data) => ({ ...data, startTime: val }))
                  }
                  label="Start time"
                />
              </Box>
              <Box width={"100%"} ml="10px">
                <TimePicker
                  value={formData.endTime}
                  onChange={handleOnEndtime}
                  label="End time"
                />
              </Box>
            </Flex>
            <Box mt="50px" mb="20px" width={"300px"}>
              <TextArea       onChange={(e) => setMessage(e.target.value)}
                              size="3" placeholder="What's your request about" />
            </Box>
            <Flex justify="center" align="center">
              <Button
                size="3"
                disabled={false}
                onClick={() => generateRecordings()}
              >
                {generating ? "Generating..." : "Send a recording request"}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default RequestRecordModal;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Text,
  Flex,
  TextField,
  Link,
  Button,
  Checkbox,
} from "@radix-ui/themes";
// import Logo from "./atom-logo.svg";
import Logo from "./atom-logo.jpg";
import { toast } from "react-toastify";
import { LuUser2, LuKeyRound } from "react-icons/lu";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api/api";

const Login = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const hasHandledCode = useRef(false);

  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = formData.email;
    localStorage.setItem("email", email);
    const payload = {
      password: formData.password,
      email,
    };
    try {
      const response = await api.post("/auth/login", payload);
      localStorage.setItem("accessToken", response.data?.accessToken);
      notifySuccess("Logged in successfully");
      navigate("/dashboard/camera");
    } catch (err) {
      notifyError(err.response.data?.error ?? "Log in unsuccessful");
    }
  };

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      if (!hasHandledCode.current) {
        hasHandledCode.current = true;
        handleAccCallback(code);
      }
    }
  }, [searchParams]);

  const handleAccCallback = async (code) => {
    try {
      setLoading(true);
      const response = await api.post("/auth/acc-login", { code });
      localStorage.setItem("accessToken", response.data.accessToken);
      setLoading(false);
      window.history.replaceState({}, "", "/login");
      navigate("/dashboard/camera");
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 400) {
        notifyError(
          "No account found. Redirecting you to create an account..."
        );

        const link = document.createElement("a");
        link.href = "https://www.atom.construction/#contact";
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        setTimeout(() => {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, 3000);
      } else {
        console.error("Error:", error.response?.data || error.message);
        notifyError("An unexpected error occurred. Please try again later.");
      }
      console.error("ACC login failed:", error);
    }
  };

  const handleAccLogin = async () => {
    try {
      const response = await api.get("/auth/acc-auth-url?type=login");
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Failed to get ACC auth URL:", error);
    }
  };

  return (
    <Flex justify="center" align="center" minHeight="100vh" overflow="hidden">
      <Box
        style={{
          background: "#F8F8F8",
          border: "1px solid #A5A8A6",
          borderRadius: 10,
          padding: 60,
        }}
        width={{
          lg: 800,
          initial: "100vw",
        }}
        height={{
          lg: 670,
          initial: "100vh",
        }}
      >
        <form onSubmit={handleLogin}>
          <Flex
            justify={{
              lg: "start",
              initial: "center",
            }}
            align="center"
            gapX="280px"
          >
            <Flex direction="column" display={{ lg: "flex", initial: "none" }}>
              <Text size="7" mb="3" weight="bold">
                Log In
              </Text>
              <Text size="3">
                Have an autodesk account ?{" "}
                <Button
                  type="button"
                  variant="soft"
                  onClick={handleAccLogin}
                  loading={loading}
                >
                  Log in with Autodesk
                </Button>
              </Text>
            </Flex>
            <Flex direction="column" align="center">
              <Box width="100px" height="100px">
                <img
                  src={Logo}
                  alt="Atom logo"
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
              <Text size="6" mt="3" weight="bold">
                ATOM
              </Text>
            </Flex>
          </Flex>
          <Flex
            justify={{
              lg: "start",
              initial: "center",
            }}
            gapX="30px"
            mt="80px"
          >
            <Box minWidth="320px">
              <TextField.Root
                type="email"
                placeholder="Username"
                size="3"
                name="email"
                value={formData.email}
                required
                onChange={handleChange}
              >
                <TextField.Slot side="right">
                  <LuUser2 height="16" width="16" />
                </TextField.Slot>
              </TextField.Root>
            </Box>
          </Flex>
          <Flex
            justify={{
              lg: "start",
              initial: "center",
            }}
            gapX="30px"
            mt="20px"
          >
            <Box minWidth="320px">
              <TextField.Root
                type="password"
                placeholder="Password"
                size="3"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              >
                <TextField.Slot side="right">
                  <LuKeyRound height="16" width="16" />
                </TextField.Slot>
              </TextField.Root>
            </Box>
          </Flex>
          <Flex
            gapX={{
              lg: "60px",
              initial: 0,
            }}
            gapY={{
              lg: 0,
              initial: "20px",
            }}
            mt="60px"
            align="center"
            direction={{
              lg: "row",
              initial: "column",
            }}
            justify={{
              lg: "start",
              initial: "center",
            }}
          >
            <Button
              radius="full"
              size="3"
              style={{ width: "200px" }}
              type="submit"
              ml={{
                lg: "60px",
                initial: 0,
              }}
              disabled={loading}
            >
              Log In
            </Button>
            <Flex gap="2" align="center">
              <Checkbox />
              <Text size="2">Remember your password</Text>
            </Flex>
          </Flex>
          <Flex
            justify="center"
            mt={{
              lg: "50px",
              initial: "5px",
            }}
          >
            <Link href="/forgot-password" underline="always">
              Forgot your password?
            </Link>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
};

export default Login;

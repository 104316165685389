import { Flex, Box, Text, Button, Select } from "@radix-ui/themes";
import { keyframes, styled } from "@stitches/react";
import React, { useEffect, useState } from "react";
import colors from "./colors";
import { toast } from "react-toastify";
import api from "../api/api";
import { useAppContext } from "../context/appContext";

const Container = styled(Box, {
  background: colors.background,
  padding: "20px 10px 20px 10px",
});

const Main = styled(Flex, {
  background: "#fff",
  height: "100vh",
  borderRadius: "5px",
  boxShadow: "0px 4px 10px 0px #D9D9D9",
});

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const Loader = styled(Flex, {
  border: `4px solid ${colors.deepGray}`,
  borderTop: `4px solid ${colors.base}`,
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: `${spin} 1s linear infinite`,
});

const isLocalhost = window.location.hostname === "localhost";

const LinkAutodeskAccount = () => {
  const { projects: atomProjects, user } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [linking, setLinking] = useState(false);
  const [linkedProject, setLinkedProject] = useState(null);
  const [hubProjects, setHubProjects] = useState([]);
  const [formData, setFormData] = useState({
    atomProject: "",
    hubProject: "",
  });

  const notifySuccess = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const handleLogin = () => {
    const baseURL = isLocalhost
      ? "http://localhost:3001/platform/api"
      : "https://web.atom.construction/platform/api";

    window.location.replace(`${baseURL}/autodesk/auth/login`);
  };

  useEffect(() => {
    async function checkAuth() {
      setLoading(true);
      try {
        await api.get(`/autodesk/auth/profile?`, { withCredentials: true });
        setIsLoggedIn(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error checking authentication:", err);
      }
    }
    checkAuth();
  }, []);

  useEffect(() => {
    const getHubProjects = async () => {
      try {
        const response = await api.get(`/autodesk/hubs/projects`, {
          withCredentials: true,
        });
        setHubProjects(response.data);
      } catch (err) {
        console.error("Error getting all hubs projects");
      }
    };
    getHubProjects();
  }, []);

  const handleLinkProject = async (e) => {
    e.preventDefault();
    setLinking(true);
    try {
      const response = await api.put(
        `/project/${formData.atomProject}/link-project`,
        {
          accProjectId: formData.hubProject,
        },
        { withCredentials: true }
      );
      setLinkedProject(response.data);
      setLinking(false);
    } catch (err) {
      setLinking(false);
      console.error("Error linking project");
    }
  };

  const linkAgain = () => {
    setLinkedProject(false);
    setFormData({ atomProject: "", hubProject: "" });
  };

  if (loading) {
    return (
      <Container>
        <Main align="center" justify="center">
          <Loader />
        </Main>
      </Container>
    );
  }

  return (
    <Container>
      <Main justify="center" align="center">
        {!isLoggedIn && <Button onClick={handleLogin}>Link ACC Account</Button>}
        {isLoggedIn && !linkedProject && (
          <form onSubmit={handleLinkProject}>
            <Flex justify="center" align="center">
              <Text size="3" color="violet" weight="bold">
                Sync ACC project with Atom project
              </Text>
            </Flex>
            <Box mt="20px">
              <Select.Root
                size="3"
                name="atomProject"
                value={formData.atomProject}
                onValueChange={(value) => {
                  setFormData((data) => ({
                    ...data,
                    atomProject: parseInt(value),
                  }));
                }}
              >
                <Select.Trigger
                  style={{ width: "320px" }}
                  placeholder="Select Atom project"
                  required
                />
                <Select.Content>
                  {atomProjects.map((project) => (
                    <Select.Item value={project.id} key={project.id}>
                      {project.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Box>
            <Box mt="10px">
              <Select.Root
                size="3"
                name="hubProject"
                value={formData.hubProject}
                onValueChange={(value) =>
                  setFormData((data) => ({
                    ...data,
                    hubProject: value,
                  }))
                }
              >
                <Select.Trigger
                  style={{ width: "320px" }}
                  placeholder="Select ACC project"
                  required
                />
                <Select.Content>
                  {hubProjects.map((project) => (
                    <Select.Item
                      value={`${project.id}#${project.relationships.hub.data.id}`}
                      key={project.id}
                    >
                      {project.attributes.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Box>
            <Flex justify="center" align="center" mt="10px">
              <Button
                type="submit"
                size="3"
                radius="full"
                disabled={
                  !formData.atomProject || !formData.hubProject || linking
                }
              >
                {linking ? "Linking..." : "Link Projects"}
              </Button>
            </Flex>
          </form>
        )}
        {isLoggedIn && linkedProject && (
          <Flex direction="column" justify="center" align="center">
            <Text size="3" weight="bold" align="center" color="violet">
              All set !
            </Text>
            <Button mt="2" onClick={linkAgain}>
              Link again
            </Button>
          </Flex>
        )}
      </Main>
    </Container>
  );
};

export default LinkAutodeskAccount;

import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  TextField,
  Select,
  Button,
} from "@radix-ui/themes";
import Logo from "./atom-logo.jpg";
import { LuUser2, LuKeyRound } from "react-icons/lu";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api/api";
import { userRoles } from "./constants";

const Signup = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    role: "",
    privacyConsent: false,
  });
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const [loading, setLoading] = useState(false);
  const hasHandledCode = useRef(false);

  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRole = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      role: value,
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!formData.privacyConsent) {
      notifyError("Please accept the privacy policy and terms of service");
      return;
    }
    const payload = {
      name: `${formData.firstName} ${formData.lastName}`,
      password: formData.password,
      role: formData.role,
      email,
    };
    if (formData.password !== formData.confirmPassword) {
      notifyError("Passwords do not match");
      return;
    }
    try {
      const response = await api.post("/auth/signup", payload);
      localStorage.setItem("accessToken", response.data?.accessToken);
      notifySuccess("Signed up successfully");
      setSuccess(true);
    } catch (err) {
      notifyError(err.response.data?.error ?? "Signup unsuccessful");
    }
  };

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      if (!hasHandledCode.current) {
        hasHandledCode.current = true;
        handleAccCallback(code);
      }
    }
  }, [searchParams]);

  const handleAccCallback = async (code) => {
    try {
      setLoading(true);
      const storedEmail = sessionStorage.getItem("acc_signup_email");

      const response = await api.post("/auth/acc-signup", {
        code,
        email: storedEmail,
      });

      sessionStorage.removeItem("acc_signup_email");
      localStorage.setItem("accessToken", response.data.accessToken);
      setLoading(false);
      navigate("/dashboard/camera");
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 400) {
        notifyError("You need to have been onboarded first to signup with ACC");
      } else {
        console.error("Error:", error.response?.data || error.message);
        notifyError("An unexpected error occurred. Please try again later.");
      }
      console.error("ACC login failed:", error);
    }
  };

  const handleAccLSignup = async () => {
    try {
      sessionStorage.setItem("acc_signup_email", email);
      const response = await api.get("/auth/acc-auth-url?type=signup");
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Failed to get ACC auth URL:", error);
    }
  };

  const passwordsMatch = formData.password === formData.confirmPassword;

  return (
    <Flex justify="center" align="center" minHeight="100vh">
      <Box
        style={{
          background: "#F8F8F8",
          border: "1px solid #A5A8A6",
          borderRadius: 10,
          padding: 60,
        }}
        width={{
          lg: 800,
          initial: "100vw",
        }}
        height={{
          lg: 670,
          initial: "100vh",
        }}
      >
        {success ? (
          <Box>
            <Flex justify="end">
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex direction="column" justify="center" align="center" mt="70px">
              <Text size="5" weight="bold" align="center">
                All set !
              </Text>
              <Text size="3" align="center" mt="10px">
                Your eyes on the site are ready to use !
              </Text>
              <Button
                radius="full"
                size="4"
                style={{ width: "200px" }}
                mt="60px"
                onClick={() => navigate("/dashboard/camera")}
              >
                Go to platform
              </Button>
            </Flex>
          </Box>
        ) : (
          <form onSubmit={handleSignup}>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              align="center"
              gapX="280px"
            >
              <Flex
                direction="column"
                display={{ lg: "flex", initial: "none" }}
              >
                <Text size="7" mb="3" weight="bold">
                  Get started now
                </Text>
                <Text size="3">
                  Have an autodesk account ?{" "}
                  <Button
                    type="button"
                    variant="soft"
                    onClick={handleAccLSignup}
                    loading={loading}
                  >
                    Sign up with Autodesk
                  </Button>
                </Text>
              </Flex>
              <Flex direction="column" align="center">
                <Box width="100px" height="100px">
                  <img
                    src={Logo}
                    alt="Atom logo"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Text size="6" mt="3" weight="bold">
                  ATOM
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              direction={{
                lg: "row",
                initial: "column",
              }}
              gapX={{
                lg: "30px",
                initial: "0",
              }}
              gapY={{
                lg: "0",
                initial: "15px",
              }}
              mt="40px"
            >
              <Box minWidth={{ lg: "320px", initial: "100%" }}>
                <TextField.Root
                  placeholder="First name"
                  size="3"
                  name="firstName"
                  value={formData.firstName}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuUser2 height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
              <Box minWidth={{ lg: "320px", initial: "100%" }}>
                <TextField.Root
                  placeholder="Last name"
                  size="3"
                  name="lastName"
                  value={formData.lastName}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuUser2 height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
            </Flex>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              direction={{
                lg: "row",
                initial: "column",
              }}
              gapX={{
                lg: "30px",
                initial: "0",
              }}
              gapY={{
                lg: "0",
                initial: "15px",
              }}
              mt="20px"
            >
              <Box minWidth={{ lg: "320px", initial: "100%" }}>
                <TextField.Root
                  type="password"
                  placeholder="Choose a password"
                  size="3"
                  name="password"
                  value={formData.password}
                  required
                  onChange={handleChange}
                >
                  <TextField.Slot side="right">
                    <LuKeyRound height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
              </Box>
              <Box minWidth={{ lg: "320px", initial: "100%" }}>
                <TextField.Root
                  type="password"
                  placeholder="Confirm your password"
                  size="3"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                >
                  <TextField.Slot side="right">
                    <LuKeyRound height="16" width="16" />
                  </TextField.Slot>
                </TextField.Root>
                {formData.confirmPassword && !passwordsMatch && (
                  <Text size="2" color="tomato">
                    Passwords do not match
                  </Text>
                )}
              </Box>
            </Flex>
            <Flex
              justify={{
                lg: "start",
                initial: "center",
              }}
              direction={{
                lg: "row",
                initial: "column",
              }}
              gapX={{
                lg: "30px",
                initial: "0",
              }}
              gapY={{
                lg: "0",
                initial: "15px",
              }}
              mt="20px"
            >
              <Box minWidth={{ lg: "320px", initial: "100%" }}>
                <Select.Root
                  size="3"
                  name="role"
                  value={formData.role}
                  onValueChange={handleRole}
                >
                  <Select.Trigger
                    style={{ width: "100%" }}
                    placeholder="Your role"
                    required
                  />
                  <Select.Content style={{ width: "100%" }}>
                    {userRoles.map((role) => (
                      <Select.Item value={role.id} key={role.id}>
                        {role.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </Box>
            </Flex>
            <Flex
              justify="center"
              align="center"
              mt="30px"
              direction="column"
              gap="3"
            >
              <Flex align="center" gap="2">
                <input
                  type="checkbox"
                  checked={formData.privacyConsent}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      privacyConsent: e.target.checked,
                    }));
                  }}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "Please confirm the privacy policy"
                    );
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                  }}
                />
                <Text size="2">
                  I agree to the{" "}
                  <Link
                    href="/privacy-policy"
                    underline="always"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy and terms of service
                  </Link>
                </Text>
              </Flex>
              <Button
                radius="full"
                size="4"
                style={{ width: "200px" }}
                type="submit"
              >
                Sign up
              </Button>
              {/* <Button
                radius="full"
                size="4"
                type="button"
                variant="soft"
                onClick={handleAccLSignup}
                loading={loading}
              >
                Sign Up with ACC
              </Button> */}
            </Flex>
          </form>
        )}
      </Box>
    </Flex>
  );
};

export default Signup;

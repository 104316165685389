import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { Box } from "@radix-ui/themes";
import api from "../api/api";

const PrivacyPolicy = () => {
  const [privacyUrl, setPrivacyUrl] = useState("");
  const zoomPluginInstance = zoomPlugin();

  useEffect(() => {
    const fetchPresignedPrivacy = async () => {
      try {
        const response = await api.get("/report/privacy/presigned");
        setPrivacyUrl(response.data.url);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPresignedPrivacy();
  }, []);

  return (
    <Box>
      {privacyUrl ? (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={privacyUrl} plugins={[zoomPluginInstance]} />
        </Worker>
      ) : (
        <p>loading......</p>
      )}
    </Box>
  );
};

export default PrivacyPolicy;

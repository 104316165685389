import React, { useEffect, useState } from "react";
import launchViewer from "./viewerFunctions";
import {
  Box,
  Button,
  Callout,
  Dialog,
  Flex,
  Select,
  Text,
} from "@radix-ui/themes";
import RebarVisualization from "./Rebar";
import { useAppContext } from "../../context/appContext";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import api from "../../api/api";
import { request } from "../../api/request";
import { formatDate } from "../../utils";
import { useNavigate } from "react-router-dom";

const bucketName = "atom-construction-bucket-eu";

const Viewer = () => {
  const navigate = useNavigate();
  const { selectedProject } = useAppContext();
  const [formData, setFormData] = useState({
    element: "",
    object: "",
    floor: "",
    scannedAt: "",
  });
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);
  const [floors, setFloors] = useState([]);
  const [elements, setElements] = useState([]);
  const [objects, setObjects] = useState([]);

  const [currentUrn, setCurrentUrn] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  const notifyError = (msg) => toast.error(msg);

  const handleChange = async (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const project = selectedProject?.name;

    if (name === "floor") {
      setFormData((prev) => ({
        ...prev,
        floor: value,
        element: "",
        object: "",
        scannedAt: "",
      }));
      setElements([]);
      setObjects([]);
      setAvailableDates([]);

      if (value) {
        try {
          const response = await api.get("/autodesk/viewer-files/hierarchy", {
            params: { project, floor: value },
          });
          setElements(response.data);
        } catch (error) {
          console.error("Error fetching elements", error);
        }
      }
    }

    if (name === "element") {
      setFormData((prev) => ({
        ...prev,
        element: value,
        object: "",
        scannedAt: "",
      }));
      setObjects([]);
      setAvailableDates([]);

      if (value) {
        try {
          const response = await api.get("/autodesk/viewer-files/hierarchy", {
            params: { project, floor: formData.floor, element: value },
          });
          setObjects(response.data);
        } catch (error) {
          console.error("Error fetching objects", error);
        }
      }
    }

    if (name === "object") {
      setFormData((prev) => ({
        ...prev,
        object: value,
        scannedAt: "",
      }));
      setAvailableDates([]);

      if (value) {
        try {
          const response = await api.get("/autodesk/viewer-files/hierarchy", {
            params: {
              project,
              floor: formData.floor,
              element: formData.element,
              object: value,
            },
          });
          setAvailableDates(response.data);
        } catch (error) {
          if (error.response?.status === 404) {
            notifyError("No scanned data found for the selected object.");
          } else {
            console.error("Error fetching scanned dates", error);
          }
        }
      }
    }

    if (name === "scannedAt") {
      try {
        const response = await api.get(
          `/autodesk/viewer-files/search?floor=${formData.floor}&element=${formData.element}&object=${formData.object}&date=${value}&project=${selectedProject.name}`
        );
        if (!response.data) {
          notifyError("Couldn't find a viewer file that matched request");
          return;
        }
        populateData(response.data);
      } catch (error) {
        if (error.response?.status === 404) {
          notifyError("Couldn't find a viewer file that matched request");
        } else {
          console.error("Error searching for viewer file");
        }
      }
    }
  };

  const onFetch = (data) => {
    if (data === null) {
      notifyError("Couldn't find a scanned set for the AB");
      return;
    }
    setCurrentData(data);
    setOpen(true);
  };

  const populateData = async (viewerFile) => {
    setCurrentUrn(viewerFile.urn);
    setFormData({
      floor: viewerFile.floor,
      element: viewerFile.element,
      object: viewerFile.object,
      scannedAt: viewerFile.scannedAt,
    });

    const project = selectedProject?.name;

    try {
      // Fetch elements for the selected floor
      const elementsResponse = await api.get(
        "/autodesk/viewer-files/hierarchy",
        {
          params: { project, floor: viewerFile.floor },
        }
      );
      setElements(elementsResponse.data);

      if (viewerFile.element) {
        // Fetch objects for the selected floor and element
        const objectsResponse = await api.get(
          "/autodesk/viewer-files/hierarchy",
          {
            params: {
              project,
              floor: viewerFile.floor,
              element: viewerFile.element,
            },
          }
        );
        setObjects(objectsResponse.data);
      }

      if (viewerFile.object) {
        // Fetch scanned dates for the selected floor, element, and object
        const datesResponse = await api.get(
          "/autodesk/viewer-files/hierarchy",
          {
            params: {
              project,
              floor: viewerFile.floor,
              element: viewerFile.element,
              object: viewerFile.object,
            },
          }
        );
        setAvailableDates(datesResponse.data);
      }
    } catch (error) {
      console.error(
        "Error fetching related elements, objects, or scanned dates",
        error
      );
    }
  };

  const fetchFloors = async () => {
    try {
      const response = await api.get("/autodesk/viewer-files/hierarchy", {
        params: { project: selectedProject?.name },
      });
      setFloors(response.data);
    } catch (error) {
      console.error("Error fetching floors", error);
    }
  };

  const fetchRecentViewerFile = async () => {
    try {
      const response = await api.get(
        `/autodesk/viewer-files/recent?project=${selectedProject.name}`
      );
      await populateData(response.data.recentFile);
    } catch (error) {
      console.error("Error fetching recent viewer file");
    }
  };

  const handleViewReport = () => {
    const queryParams = new URLSearchParams({
      floor: formData.floor,
      element: formData.element,
      object: formData.object,
      date: formData.scannedAt,
    }).toString();

    navigate(`/dashboard/insights?${queryParams}`);
  };

  const hasFullData = formData.floor && formData.element && formData.object;

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        await fetchFloors();
        fetchRecentViewerFile();
      } catch (error) {
        console.error(error);
      }
    };
    setupInitialData();
  }, []);

  useEffect(() => {
    const hierachy = {
      ...formData,
      client: selectedProject?.client,
      project: selectedProject?.name,
    };

    if (hasFullData) {
      launchViewer("viewerDiv", currentUrn, hierachy, onFetch);
    } else {
      console.warn("Hierarchy is incomplete. Viewer not fully operational.");
    }
  }, [currentUrn]);

  return (
    <Box mt="20px">
      <Flex align="center" mb="20px" ml="20px" wrap="wrap">
        <Flex direction="column">
          <Text size="3" color="violet" weight="bold">
            Floor
          </Text>
          <Select.Root
            size="3"
            name="floor"
            mt="3px"
            value={formData.floor}
            onValueChange={(val) => handleChange("floor", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Floor"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {floors.map((floor) => (
                <Select.Item value={floor} key={floor}>
                  {floor}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>

        <Flex direction="column" ml="5px">
          <Text size="3" color="violet" weight="bold">
            Elements
          </Text>
          <Select.Root
            size="3"
            name="element"
            mt="3px"
            disabled={!formData.floor}
            value={formData.element}
            onValueChange={(val) => handleChange("element", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Element"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {elements.map((element) => (
                <Select.Item value={element} key={element}>
                  {element}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>

        <Flex direction="column" ml="5px">
          <Text size="3" color="violet" weight="bold">
            Objects
          </Text>
          <Select.Root
            size="3"
            name="object"
            mt="3px"
            disabled={!formData.element}
            value={formData.object}
            onValueChange={(val) => handleChange("object", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Object"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {objects.map((object) => (
                <Select.Item value={object} key={object}>
                  {object}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>

        <Flex direction="column" ml="5px">
          <Text size="3" color="violet" weight="bold">
            Dates
          </Text>
          <Select.Root
            size="3"
            name="scannedAt"
            mt="3px"
            disabled={!formData.object}
            value={formData.scannedAt}
            onValueChange={(val) => handleChange("scannedAt", val)}
          >
            <Select.Trigger
              style={{ width: "320px" }}
              placeholder="Date"
              required
            />
            <Select.Content style={{ width: "320px" }}>
              {availableDates.map((date) => (
                <Select.Item value={date} key={date}>
                  {formatDate(date)}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>
        {hasFullData && currentUrn && (
          <Flex mb="10px" mt="10px">
            <Button size="1" variant="soft" onClick={handleViewReport}>
              view report
            </Button>
          </Flex>
        )}
      </Flex>

      {!hasFullData ? (
        <Flex justify="center" align="center">
          <Callout.Root color="red">
            <Callout.Icon>
              <FaInfoCircle />
            </Callout.Icon>
            <Callout.Text>
              You will need to select a full hierarchy to preview viewer
            </Callout.Text>
          </Callout.Root>
        </Flex>
      ) : (
        <div
          style={{ position: "absolute", width: "100%", height: "80%" }}
          id="viewerDiv"
        />
      )}

      {open && (
        <Dialog.Root
          open={open}
          onOpenChange={() => setOpen((open) => (open = !open))}
        >
          <Dialog.Content maxWidth="600px" maxHeight="600px">
            <RebarVisualization data={currentData} />
          </Dialog.Content>
        </Dialog.Root>
      )}
    </Box>
  );
};

export default Viewer;
